@import "src/theme/spacing";

.button-component {
  display: flex;
  align-items: center;
  text-align: center;
  background: #D93600;
  border-radius: $spacing2;
  padding: $spacing4;
  width: 100%;
  border: 0;
  color: #FFF;
  justify-content: center;
  margin-top: $spacing4;
}

.button-component:hover {
  background: #d03500;
  cursor: pointer;
}

button[disabled], button[disabled]:hover {
  background-color: #CCC;
  cursor: default;
}