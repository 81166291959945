body {
    background: #FFF;
}


::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0 none;
    border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
    background: #b8b8b8;
}
::-webkit-scrollbar-thumb:active {
    background: #b8b8b8;
}
::-webkit-scrollbar-track {
    background: #666666;
    border: 0 none #b8b8b8;
    border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
    background: #666666;
}
::-webkit-scrollbar-track:active {
    background: #333333;
}
::-webkit-scrollbar-corner {
    background: transparent;
}