@import "src/theme/spacing";

.individual-page {
  max-width: 390px;
  width: 100%;
  padding: $spacing5 $spacing10 $spacing10;

  &__head {
    display: flex;
    justify-content: center;
    padding: $spacing5;

    &__title {
      font-size: $spacing8;
      font-weight: 700;
    }
  }

  &__card {
    margin-bottom: $spacing6;
  }

  .divider {
    border-top: 1px solid #9e9e9e;
    margin: $spacing5 0;
  }
}
