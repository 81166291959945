@import "src/theme/spacing";

.section {
  border: 1px solid #FFC926;
  padding: 8px;
  border-radius: 5px;
  font-weight: 700;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: $spacing5;
  font-size: 18px;
}